import Vue from 'vue'
import Vuex from 'vuex'
// eslint-disable-next-line import/no-cycle
import axios from '@axios'
// eslint-disable-next-line import/no-cycle

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    applications: {
      new: 0,
      abandoned_solicitor: 0,
      not_accepted_solicitor: 0,
      abandoned_surrogate: 0,
      not_accepted_surrogate: 0,
      finished: 0,
      declined: 0,
    },
  },
  getters: {

    get: state => key => {
      const keys = key.split('.');

      return keys.reduce((a, c) => a[c], state);
    },
  },
  actions: {
    async fetchApplicationStatistics(ctx) {
      // const response = await axios.get('/applications/statistics')
      // ctx.state.applications = response.data.data;
    },
  },
  mutations: {
  },
  modules: {
  },
})
